<template>
  <div v-if="jobs">
    <div>This title is using the workflow.</div>
    <div>
      <b-collapse id="collapse-1" class="mt-2">
        <b-card>
          <p>
            The <i class="fa fa-square-o blue"></i> button is used to tick the
            job as completed. Completed jobs show
            <i class="fa fa-square-check blue"></i> and can be set to in
            progress again by un-ticking it.
            <br />
            The <i class="fa fa-edit blue"></i> button is used to modify the
            job. Completed jobs can be only partially modified.
            <br />
            The <i class="fa fa-bell-slash-o blue"></i> or
            <i class="fa fa-bell-o blue"></i> button will toggle the job's
            snooze status. A snoozed job is displayed as greyed out and displays
            icon <i class="fa fa-bell-slash-o"></i> and an active job displays
            icon <i class="fa fa-bell-o"></i>. Snoozing a job will snooze it for
            a week.
            <br />
            Overdue jobs are displayed highlighted in red and jobs in progress
            are highlighted in green, both unless snoozed.
            <br />
            The <i class="fa fa-eye-slash blue"></i> or
            <i class="fa fa-eye blue"></i> button will toggle the job's watch
            status. Watching a job means getting notified of any changes to the
            job's status. A watched job displays icon
            <i class="fa fa-eye"></i> and an un-watched job displays icon
            <i class="fa fa-eye-slash"></i>.
            <br />
            The <i class="fa fa-trash red"></i> button will remove the job from
            the product's workflow.
            <br />
            <br />The buttons at the bottom of the screen are used to add one or
            more templates (Add Template), to add individual jobs (Add Job) to
            this record, and to update the leaders (Leaders) for each template
            in use. The Delete Workflow button removes all jobs and templates in
            their entirety.
          </p>
        </b-card>
      </b-collapse>
      <b-button v-b-toggle.collapse-1 variant="light" style="float: right"
        >Page Info</b-button
      >
    </div>
    <base-table
      class="mt-3"
      :filters-enabled="false"
      :columns="columns"
      :initial-data="jobs"
      :sorted-by="'target_date'"
      sort-direction="asc"
    >
      <template v-slot:name="{ record }">
        <span :class="highLightJobOnStatus(record)">{{ record.name }}</span>
      </template>
      <template v-slot:template_name="{ record }">
        <span :class="highLightJobOnStatus(record)">{{
          record.template_name
        }}</span>
      </template>
      <template v-slot:assignees="{ record }">
        <span :class="highLightJobOnStatus(record)">{{
          record.assignees
        }}</span>
      </template>
      <template v-slot:start_date="{ record }">
        <span :class="highLightJobOnStatus(record)">{{
          record.start_date | parseDate(dateOptions.format, dateOptions)
        }}</span>
      </template>
      <template v-slot:target_date="{ record }">
        <span :class="highLightJobOnStatus(record)">{{
          record.target_date | parseDate(dateOptions.format, dateOptions)
        }}</span>
      </template>
      <template v-slot:completed_on="{ record }">
        <icon-btn
          :icon="record.job_is_completed ? 'square-check' : 'square-o'"
          :title="
            record.job_is_completed
              ? 'Un-mark this job as completed.'
              : 'Mark this job as completed.'
          "
          :disabled="!$permitted('workflow/uncomplete')"
          @click="
            toggleJobCompletedStatus(
              record.hidden.job_id,
              record.job_is_completed
                ? 'Are you sure that you want to UN-mark this job as completed?'
                : 'Are you sure that you want to mark this job as completed?'
            )
          "
        ></icon-btn>
        <span :class="highLightJobOnStatus(record)">
          {{
            record.completed_on | parseDate(dateOptions.format, dateOptions)
          }}</span
        >
      </template>
      <template v-slot:job_is_completed="{ record }">
        <icon-btn
          :icon="'edit'"
          :title="'Edit the parameters of this job.'"
          :link="
            $baseUrl +
            '/cakeworkflow/editJob/' +
            productId +
            '/' +
            record.hidden.job_id +
            '?return=' +
            returnTo +
            '&tab=cakeworkflow'
          "
          :disabled="!$permitted('workflow/edit')"
        ></icon-btn>
        <icon-btn
          :icon="
            record.hidden.job_is_in_progress_but_snoozed
              ? 'bell-slash-o'
              : 'bell-o'
          "
          :title="
            record.hidden.job_is_in_progress_but_snoozed
              ? 'Activate (un-snooze) the alerts for this job.'
              : 'Snooze the alerts for this job.'
          "
          :disabled="
            record.completed_on != null || !$permitted('workflow/edit')
          "
          @click="
            toggleJobSnooze(
              record.hidden.job_id,
              record.hidden.job_is_in_progress_but_snoozed
                ? 'Are you sure that you want to activate the alerts for this job?'
                : 'Are you sure that you want to SNOOZE the alerts for this job?'
            )
          "
        ></icon-btn>
        <icon-btn
          :icon="userIsWatchingTask(record) ? 'eye' : 'eye-slash'"
          :title="
            userIsWatchingTask(record)
              ? 'Stop watching this job.'
              : 'Watch changes to this job.'
          "
          :disabled="
            record.completed_on != null || !$permitted('workflow/edit')
          "
          @click="toggleJobWatch(record.hidden.job_id)"
        ></icon-btn>
        <delete-icon
          :title="'Delete this job.'"
          :link="
            $baseUrl +
            '/cakeworkflow/deleteJob/' +
            record.hidden.job_id +
            '?return=' +
            returnTo +
            '&tab=cakeworkflow'
          "
          :msg="'Are you sure that you want to remove this job? You cannot undo this action!'"
          :disabled="!$permitted('workflow/edit')"
        ></delete-icon>
      </template>
    </base-table>
    <div class="mt3">
      <div class="row">
        <div class="col">
          <b-dropdown
            id="btn-assign-template"
            v-b-tooltip.hover
            dropup
            title="Select a workflow template to add to this product record."
            text="Add Template"
            variant="outline-primary"
            class="ml-2"
          >
            <b-dropdown-item
              v-for="(item, key) in templates"
              :key="key"
              :disabled="!$permitted('workflow/edit')"
              @click="addWorkflowToProduct(productId, item.template_id)"
              >{{ item.name }}</b-dropdown-item
            >
            <b-dropdown-item v-if="!templates || !templates.length" disabled
              >No (more) templates.</b-dropdown-item
            >
          </b-dropdown>
          <b-button
            v-if="$permitted('workflow/edit')"
            id="btn-edit-workflow"
            v-b-tooltip.hover
            class="ml-2"
            title="Add a job to the workflow of this record."
            variant="outline-primary"
            :href="
              $baseUrl +
              'cakeworkflow/editJob/' +
              productId +
              '?tab=cakeworkflow' +
              (returnTo ? '&return=' + returnTo : '')
            "
            >Add Job</b-button
          >
          <b-dropdown
            id="btn-modify-leaders"
            v-b-tooltip.hover
            dropup
            title="Select a workflow template to add or remove leaders."
            text="Leaders"
            variant="outline-primary"
            class="ml-2"
          >
            <b-dropdown-item
              v-for="(item, key) in templates_in_use"
              :key="key"
              :disabled="!$permitted('workflow/uncomplete')"
              :href="
                $baseUrl +
                'cakeworkflow/editProductTemplateLeaders/' +
                productId +
                '/' +
                item.template_id +
                '?tab=cakeworkflow' +
                (returnTo ? '&return=' + returnTo : '')
              "
              >{{ item.name }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="!templates_in_use || !templates_in_use.length"
              disabled
              >No template(s) in use.</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="col"></div>
        <div class="col float-right">
          <b-button
            v-if="$permitted('workflow/uncomplete')"
            id="btn-delete-workflow"
            v-b-tooltip.hover
            title="Click to clear this product's entire workflow settings."
            class="ml-2 float-right"
            variant="outline-danger"
            @click="deleteWorkflow"
            >Delete Workflow</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Displays a tab containing product workflow tasks
 * and workflow overview.
 *
 * Allows for some editing options such as copying and deleting
 * as well as being able to mark tasks as completed.
 */

import { HTTP } from "../../http-common.js";
// import { DateTime } from "luxon";
import ParseDate from "../../mixins/parseDate.js";

export default {
  name: "ProductCakeWorkflow",

  mixins: [ParseDate],

  props: {
    /**
     * The ID of the product
     */
    productId: {
      type: Number,
      required: true,
    },

    /**
     * Dump of ProductWorkflowJobs: product's workflow jobs
     */
    workflowObject: {
      type: Array,
      default: null,
    },

    /**
     * List of available templates
     */
    workflowTemplates: {
      type: Array,
      default: null,
    },

    /**
     * List of in use templates
     */
    workflowTemplatesInUse: {
      type: Array,
      default: null,
    },

    /**
     * The return to action for child pages
     */
    returnTo: {
      type: String,
      default: null,
    },
  },

  data: function () {
    console.log(this.workflowTemplates);
    console.log(this.workflowTemplatesInUse);
    return {
      templates: this.workflowTemplates,
      templates_in_use: this.workflowTemplatesInUse,
      jobs: this.workflowObject,
    };
  },

  computed: {
    columns: function () {
      return [
        { text: "Job ID", key: "job_id", type: "integer", hidden: true },
        { text: "Job", key: "name" },
        { text: "Template", key: "template_name" },
        { text: "Assignee(s)", key: "assignees" },
        { text: "Start", key: "start_date", type: "date" },
        { text: "Target", key: "target_date", type: "date" },
        { text: "Completed", key: "completed_on", type: "date" },
        {
          text: "Late",
          key: "job_is_in_progress_and_overdue",
          type: "boolean",
          hidden: true,
        },
        {
          text: "Snoozed",
          key: "job_is_in_progress_but_snoozed",
          type: "boolean",
          hidden: true,
        },
        {
          text: "Watched",
          key: "job_is_in_progress_but_snoozed",
          type: "boolean",
          hidden: true,
        },
        { text: "", key: "job_is_completed", type: "boolean" },
      ];
    },
  },

  methods: {
    /**
     * toggle job's completed status
     */
    toggleJobCompletedStatus: async function (jobId, msgAreYouSure) {
      if (!confirm(msgAreYouSure)) {
        return false;
      }

      let jobName = "";
      for (let i = 0; i < this.jobs.length; i++) {
        if (this.jobs[i].job_id == jobId) {
          jobName = this.jobs[i].name;
        }
      }

      try {
        let postData = new URLSearchParams();
        const url =
          this.$baseUrl + "cakeworkflow/toggleJobCompletedStatus/" + jobId;
        const response = await HTTP.post(url, postData);

        if (response) {
          // update job list
          this.jobs = response.data["jobs"];

          // display notification messages
          for (let i = 0; i < response.data["messages"].length; i++) {
            this.$bvToast.toast(response.data["messages"][i]["message"], {
              title: jobName,
              autoHideDelay: response.data["messages"][i]["success"]
                ? 5000
                : 7500,
              appendToast: true,
              toaster: "b-toaster-top-right",
              variant: response.data["messages"][i]["success"]
                ? "success"
                : "warning",
            });
          }
        }
      } catch (error) {
        this.$bvToast.toast(
          "There was an error updating job '" + jobName + "'.",
          {
            title: jobName,
            noAutoHide: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            variant: "danger",
          }
        );
      }
    },

    /**
     * toggle job's snoozed status
     */
    toggleJobSnooze: async function (jobId, msgAreYouSure) {
      if (!confirm(msgAreYouSure)) {
        return false;
      }

      let jobName = "";
      for (let i = 0; i < this.jobs.length; i++) {
        if (this.jobs[i].job_id == jobId) {
          jobName = this.jobs[i].name;
        }
      }

      try {
        let postData = new URLSearchParams();
        const url = this.$baseUrl + "cakeworkflow/toggleJobSnooze/" + jobId;
        const response = await HTTP.post(url, postData);

        if (response) {
          for (let i = 0; i < this.jobs.length; i++) {
            if (this.jobs[i].job_id == jobId) {
              this.jobs[i].job_is_in_progress_but_snoozed =
                !this.jobs[i].job_is_in_progress_but_snoozed;

              break;
            }
          }

          // display notification messages
          for (let i = 0; i < response.data.length; i++) {
            this.$bvToast.toast(response.data[i]["message"], {
              title: jobName,
              autoHideDelay: response.data[i]["success"] ? 5000 : 7500,
              appendToast: true,
              toaster: "b-toaster-top-right",
              variant: response.data[i]["success"] ? "success" : "warning",
            });
          }
        }
      } catch (error) {
        this.$bvToast.toast(
          "There was an error updating job '" + jobName + "'.",
          {
            title: jobName,
            noAutoHide: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            variant: "danger",
          }
        );
      }
    },

    /**
     * Form a timestamp in milliseconds to ISO date
     */
    formatDate: function (timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero for single-digit months
      const day = String(date.getDate()).padStart(2, "0"); // Add leading zero for single-digit days

      const hour = String(date.getHours()).padStart(2, "0"); // Add leading zero for single-digit hours (optional for 24h format)
      const minute = String(date.getMinutes()).padStart(2, "0");
      const second = String(date.getSeconds()).padStart(2, "0");

      // const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      const formattedDate =
        year +
        "-" +
        month.toString() +
        "-" +
        day.toString() +
        " " +
        hour.toString() +
        ":" +
        minute.toString() +
        ":" +
        second.toString();
      return formattedDate;
    },

    /**
     * toggle user's job watch status
     */
    toggleJobWatch: async function (jobId) {
      let jobName = "";
      for (let i = 0; i < this.jobs.length; i++) {
        if (this.jobs[i].job_id == jobId) {
          jobName = this.jobs[i].name;
        }
      }

      try {
        let postData = new URLSearchParams();
        const url = this.$baseUrl + "cakeworkflow/toggleJobWatch/" + jobId;
        const response = await HTTP.post(url, postData);

        if (response) {
          // find the correct job
          for (let i = 0; i < this.jobs.length; i++) {
            if (this.jobs[i].job_id == jobId) {
              let foundUser = false;
              // find the user in the watch list
              for (
                let j = 0;
                j < this.jobs[i].product_workflow_job_watchers.length;
                j++
              ) {
                if (
                  this.jobs[i].product_workflow_job_watchers[j].user_id ==
                  this.$user.user_id
                ) {
                  this.jobs[i].product_workflow_job_watchers.splice(j, 1);
                  foundUser = true;
                  break;
                }
              }

              // not found then add user
              if (!foundUser) {
                let now = this.formatDate(Date.now());
                this.jobs[i].product_workflow_job_watchers.push({
                  job_id: jobId,
                  user_id: this.$user.user_id,
                  last_altered_by: this.$user.user_id,
                  last_altered_on: now,
                });
              }

              break;
            }
          }

          // display notification messages
          for (let i = 0; i < response.data.length; i++) {
            this.$bvToast.toast(response.data[i]["message"], {
              title: jobName,
              autoHideDelay: response.data[i]["success"] ? 5000 : 7500,
              appendToast: true,
              toaster: "b-toaster-top-right",
              variant: response.data[i]["success"] ? "success" : "warning",
            });
          }
        }
      } catch (error) {
        this.$bvToast.toast(
          "There was an error updating job '" + jobName + "'.",
          {
            title: jobName,
            noAutoHide: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            variant: "danger",
          }
        );
      }
    },

    /**
     * Add workflow to product
     */
    addWorkflowToProduct: async function (productId, templateId) {
      let templateName = "";
      for (let i = 0; i < this.templates.length; i++) {
        if (this.templates[i].template_id == templateId) {
          templateName = this.templates[i].name;
        }
      }

      try {
        let postData = new URLSearchParams();
        const url =
          this.$baseUrl +
          "cakeworkflow/addWorkflowToProduct/" +
          productId +
          "/" +
          templateId;
        const response = await HTTP.post(url, postData);

        if (response) {
          // update workflow list
          this.templates = response.data["templates"];

          // update job list
          this.jobs = response.data["jobs"];

          // display notification messages
          for (let i = 0; i < response.data["messages"].length; i++) {
            this.$bvToast.toast(response.data["messages"][i]["message"], {
              title: templateName,
              autoHideDelay: response.data["messages"][i]["success"]
                ? 5000
                : 7500,
              appendToast: true,
              toaster: "b-toaster-top-right",
              variant: response.data["messages"][i]["success"]
                ? "success"
                : "warning",
            });
          }
        }
      } catch (error) {
        this.$bvToast.toast(
          "There was an error adding template '" + templateName + "'.",
          {
            title: templateName,
            noAutoHide: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            variant: "danger",
          }
        );
      }
    },

    /**
     * Handled deletion of a product's workflow
     */
    deleteWorkflow: function () {
      if (
        confirm("Are you sure you want to delete the ENTIRE workflow data?") ==
        true
      ) {
        let url =
          this.$baseUrl +
          "cakeworkflow/deleteEntireWorkflow/" +
          this.productId +
          "?tab=cakeworkflow";
        if (this.returnTo) {
          url += "&return=" + this.returnTo;
        }

        //execute
        window.open(url, "_self");
      }
    },

    /**
     * Checks if a task is overdue and returns the appropriate class name to highlight
     * the task row in the table. Also applies styling for snoozwd tasks
     */
    highLightJobOnStatus: function (job) {
      // not target date or snoozed
      if (
        !job.target_date ||
        job.hidden.job_is_in_progress_but_snoozed ||
        job.job_is_completed
      ) {
        return "text-muted";
      }

      // overdue in progress and not snoozed
      if (
        job.hidden.job_is_in_progress_and_overdue /* &&
        !job.hidden.job_is_in_progress_but_snoozed */
      ) {
        return "text-danger";
      }

      // highlight job in progress
      if (job.hidden.job_is_in_progress) {
        return "text-success";
      }

      // no class needs to be applied
      return null;
    },

    /**
     * Checks whether the user is listed as a watcher for the job
     */
    userIsWatchingTask: function (job) {
      let watchers = job.hidden.product_workflow_job_watchers;

      if (watchers !== null) {
        for (let i = 0; i < watchers.length; i++) {
          // is current user a watcher for the job
          if (watchers[i].user_id == this.$user.user_id) {
            return true;
          }
        }
      }

      // not a watcher
      return false;
    },
  },
};
</script>

<style></style>
